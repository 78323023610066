import React from "react";
import "./Home.css";
import { FaMailBulk, FaPhone } from "react-icons/fa";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";
import { HelmetApp } from "../Components/Helmet.js";
import MapIframe from "../Components/iFrameMap";

const category = [
  {
    name: "Essential Oils",
    imgUrl: require("../images/certificate/p1.jpg"),
    category: "essential",
  },

  {
    name: "Carrier Oils",
    imgUrl: require("../images/certificate/p2.webp"),
    category: "carrier",
  },

  {
    name: "Aromatic Chemicals",
    imgUrl: require("../images/certificate/p3.jpg"),
    category: "aromatic",
  },

  {
    name: "Flavours",
    imgUrl: require("../images/certificate/p4.jpg"),
    category: "flavours",
  },

  {
    name: "Fragrances",
    imgUrl: require("../images/certificate/p5.jpg"),
    category: "fragrances",
  },

  {
    name: "Oleoresins",
    imgUrl: require("../images/certificate/p6.jpg"),
    category: "oleoresins",
  },

  {
    name: "Plant Extracts",
    imgUrl: require("../images/certificate/p8.webp"),
    category: "plant-extracts",
  },
];

const Home = ({ setCategoryName, setActive }) => {
  setActive("home");
  return (
    <>
      <HelmetApp
        title={
          "Essential oils  manufacturers in India | Essential Oils Exporter in Turkey  | carrier oil manufacturers in india | Ghaziabad Aromatics"
        }
        des={
          "Ghaziabad Aromatics specializes in the production of aromatic chemicals, essential oil flavours, carrier oils, perfume oils, and oleoresins."
        }
      />

      <div className="section1">
        <div className="section1Layer">
          <h1> We aim at providing natural products to the world</h1>
          {/* <h2> ISO 22000 Accredited and GLP Compliant Laboratory</h2> */}{" "}
          <br />
          <br /> <br />
          <div className="ces1BtnBox">
            <Link to={"/company"} className="btn">
              About us
            </Link>
          </div>
        </div>
      </div>

      <div className="section2 bgLayer">
        <div className="section2Container">
          <div className="box1">
            <h2>
              “Keep going, <span className="colorN"> natural</span> will find
              out its ways”{" "}
            </h2>{" "}
            <br />
            {/* <p>Our company utilize advanced technologies and expertise to extract, refine, and process natural ingredients into <span className='colorN'>high-quality products</span>.</p> */}
            <p>
              Driven by the words of our Founder, we aim at providing natural
              products to the world. Our love for nature and passion towards
              farmers well being have moved us into the manufacturing of
              essential oils, aromatic chemicals, extracts and oleoresins,
              flavours and fragrances.{" "}
              <span className="colorN">Since 1989</span> , we are focused on
              providing the very best of nature with every drop.
            </p>
            <br /> <br />{" "}
            <Link to={"/company"} className="btn1">
              Read More
            </Link>
          </div>
          <div className="box2">
            <span>
              <img
                src={require("../images/gaOwne.jpeg")}
                alt="product"
                className="roundImg"
              />
            </span>
          </div>
        </div>
      </div>

      <div className="section3 bgColor rightLayer ">
        <div className="section3Container section5Container">
          <div className="heading">
            <h2>
              {" "}
              Our <span className="colorN">Products</span>
            </h2>
            {/* <span></span> */}
          </div>

          <div className="cerItemBox">
            {category?.map((item, i) => (
              <Link
                to={`/products/${item?.category}`}
                key={item?.category}
                onClick={(e) => setCategoryName(item.name)}
                className="cerItem productItem"
              >
                <img src={item.imgUrl} alt="iso" />
                <br />
                <h2> {item.name}</h2>
              </Link>
            ))}
          </div>

          {/* <Link to={'/products'} className=' centerN btn2'>See all</Link> */}
        </div>
      </div>

      {/* <div className="bgLayer">

        <div className='section4'>
          <div className="section4Container">
            <div className="heading">
              <h2>Why Choose Us ?</h2>
              <h1>
                EXPERIENCE QUALITY SERVICE OUR R&D
              </h1>
            </div>

          </div>
        </div>


        <div className='section2'>
          <div className="section2Container">
            <div className='box1'>
              <h2><span className='colorN'>INFRASTRUCTURE </span> </h2>
              <p>We have 3 manufacturing units in India, where we have the facility of steam distillation, fractional distillation, hydrogenation, reaction vessels etc.</p>
              <p>
                All our raw material and final products are tested for quality. We have an in house lab, well equipped with various testing machines like gas chromatography ( GC), Gas chromatography Mass Spectrometry ( GCMS) with headspace technology, density meter, polarimeter, refractometer etc
              </p>

            </div>
            <div className='box2'>
              <img className='' src={'https://www.planetnatural.com/wp-content/uploads/2023/05/woman-with-flower-toast.jpg'} alt="product" />

            </div>
          </div>
        </div>
      </div> */}

      <div className="section3  bgChem">
        <div className="section3Container">
          <div className="heading">
            <h2>
              Experts <span className="colorN">Certifications</span>
            </h2>
            <span></span>
          </div>

          <div className="cerItemBox">
            <div className="cerItem">
              <img src={require("../images/certificate/c1.png")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c2.jpg")} alt="iso" />

              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c3.jpg")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c4.png")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c5.jpg")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c6.jpeg")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c7.png")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>

            <div className="cerItem">
              <img src={require("../images/certificate/c8.jpg")} alt="iso" />
              {/* <h2>	ISO 22000</h2> */}
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className="accordionContainer">
        <h1>Quality <span className='colorN'>Control</span> </h1>
        <AccordionBox />
        <br />
        <Link to={'/company'} className='btn2'>More</Link>
      </div> */}

      <div className="mapContainer">
        <MapIframe />

        {/* <iframe className='Iframe' src="https://www.google.com/maps/d/embed?mid=1GeqeVVVN3EbfUFSzgt9f5GytCHg&hl=en&ehbc=2E312F" ></iframe> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7001.898695851313!2d77.45453714113873!3d28.661235266869195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1efe3dd2bb7%3A0xae882e315a37a557!2sKavi%20Nagar%20Industrial%20Area%2C%20Sector%2017%2C%20Kavi%20Nagar%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201002!5e0!3m2!1sen!2sin!4v1717662725622!5m2!1sen!2sin" className='Iframe' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

        <div className="iframeContactContainer">
          <div className="iframContactBox">
            <img src={require("../images/certificate/map.jpg")} alt="" />
            <div>
              <img
                src={require("../images/ga-logo.jpg")}
                alt="Logo"
                width={100}
                className="logo"
              />

              <div>
                <p>
                  <FaPhone /> +91 8178948098
                </p>
                <p>
                  <FaMailBulk /> sales@ghaziabadaromatics.com
                </p>
                <p>
                  <ImLocation />
                  Kavinagar Industrial Area, Ghaziabad
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
