import React, { useEffect, useState } from "react";
import "./Products.css";
import items from "../utils/products";
import ProductItem from "../Components/ProductItem";
import Accordion from "../Components/Accordion";
import Pagination from "react-js-pagination";

import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { clearError, getProducts } from "../actions/productAction"; //clearError ,
import Loading from "../Components/loading";
import PlantGif from "../images/Plants.gif";
import { HelmetApp } from "../Components/Helmet";

const ProductPage = ({ CategoryName, categoryforbreadcrunmbs }) => {
  const dispatch = useDispatch();
  const { products, loading, error, filterProductCount } = useSelector(
    (state) => state.products
  );

  // const { keyword } = useParams();
  const { category } = useParams();
  // const [category, setCategory] = useState(cat ? cat : '');
  // console.log("error:",keyword)
  // useEffect(() => {
  //   setCategory(cat)
  // }, [cat])

  useEffect(() => {
    if (error) {
      dispatch(clearError());
    }
    // setCategory(cat)

    dispatch(getProducts(category));
  }, [dispatch, error, category]);

  products?.sort((a, b) => a.name.localeCompare(b.name));

  // Group the products alphabetically into an array of arrays
  let groupedChemicals = [];
  let currentGroup = [];
  let currentLetter = "";

  products?.forEach((chemical) => {
    let firstLetter = chemical.name.charAt(0).toUpperCase();
    if (firstLetter !== currentLetter) {
      if (currentGroup.length > 0) {
        groupedChemicals.push(currentGroup);
        currentGroup = [];
      }
      currentLetter = firstLetter;
    }
    currentGroup.push(chemical);
  });

  // Push the last group
  if (currentGroup.length > 0) {
    groupedChemicals.push(currentGroup);
  }

  const titles = [
    {
      title:
        "Essential Oils | Exporter of essential oils | MANUFACTURER OF ESSENTIAL OILS | essential oils for biopesticides | ESSENTIAL OILS USE IN CROP PROTECTION | FAMI QS CERTIFIED ESSENTIAL OILS | Essential oils for animal nutrition | ESSENTIAL OILS USE IN ANIMAL FEED | essential oils manufacturers in India | Top Essential Oil Manufacturers in India | Pure Essential Oils In mexico",
      category: "essential",
      des: "Essential oils are known for their therapeutic and aromatic properties. At Ghaziabad Aromatics, we use advanced distillation techniques to extract essential oils from various plants and herbs.",
    },

    {
      title:
        "Carrier Oils | Carrier Oil Manufacturers in India | 100% Pure Carrier Oils Manufacturer in India",
      category: "carrier",
      des: "Ghaziabad Aromatics takes pride in being a 100% pure carrier oils manufacturer. Their carrier oils are extracted from natural sources and are free of any additives or harmful chemicals.",
    },

    {
      title:
        "Aromatic oil manufacturers in India | Aromatic Oils Manufacturers in India | Aromatic Essential Oil Exporter in Thailand",
      category: "aromatic",
      des: "Welcome to Ghaziabad Aromatics, a leading manufacturer of Aromatic Essential Oil in Thailand. Our company prides itself on producing high-quality products.",
    },

    {
      title:
        "Fragrances oil manufacturers in India | Fragrance Oils Latest Price from Manufacturers in India | Perfume Oils Manufacturers in India",
      category: "fragrances",
      des: "We also manufacture perfume oils in our facility in Ghaziabad. Our perfumes are made from a blend of natural ingredients, creating unique and delightful scents that cater to different preferences.",
    },

    {
      title:
        "Oleoresins Manufacturers in India | MANUFACTURER OF OLEORESINS Bulk | Manufacturers of Oleoresins & Exporter in turkey | oleoresins oil manufacturers in India",
      category: "oleoresins",
      des: "- Ghaziabad Aromatics also specializes in oleoresins and plant extracts. These products are highly sought after for their medicinal and therapeutic properties.",
    },

    {
      title:
        "Flavor Oils Manufacturers in India | Essential Oil Flavour in uae | Oil Flavor Manufacturers in India",
      category: "flavours",
      des: "Our essential oil flavours in uae are known for their rich and authentic aroma, making them ideal for use in various industries such as cosmetics, and aromatherapy.",
    },

    {
      title:
        "MANUFACTURER OF PLANT EXTRACTS | Herbal Extracts Manufacturers in India | Plant extracts manufacturers in India | Plant Extract Manufacturers & Suppliers in Poland",
      category: "plant-extracts",
      des: "Ghaziabad Aromatics is a leading manufacturer of plant extracts and Herbal Extracts. Our plant extracts are used in the production of cosmetics, herbal medicines products.",
    },
  ];

  // console.log(products)
  return (
    <>
      {titles?.map(
        (item, i) =>
          item.category === category && (
            <HelmetApp key={i} title={item.title} des={item.des} />
          )
      )}

      <div className="productsContainer">
        <div className="aboutBanner img2">
          <div>{/* <h1 className=""> {CategoryName}  </h1> */}</div>
        </div>

        <div className="breadcrumbs">
          {categoryforbreadcrunmbs ? (
            <span>
              {" "}
              <Link to={"/"}>Home</Link> /{" "}
              <Link to={"/all-category"}>All Categories</Link> /{" "}
              <Link to={`/category/${categoryforbreadcrunmbs}`}>
                {categoryforbreadcrunmbs}
              </Link>{" "}
              / Products{" "}
            </span>
          ) : (
            <span>
              {" "}
              <Link to={"/"}>Home</Link> / Products{" "}
            </span>
          )}
        </div>

        <h1 className="headingH1"> {CategoryName} </h1>

        <div className="productsRow rightLayer ">
          {/* <div className="sidebarbox">
          <Accordion setSearch={setSearch} setCategory={setCategory} />
        </div> */}

          <div className="productsDetailBox">
            {/* <h1> {CategoryName}  </h1> */}
            {loading ? (
              <Loading />
            ) : (
              <div className="products-row">
                {products && products.length === 0 ? (
                  <div className="noProduct">
                    <img
                      src="https://img.freepik.com/free-vector/flat-design-no-data-illustration_23-2150527142.jpg"
                      alt="product"
                    />
                    <h2>No Product Found</h2>
                  </div>
                ) : (
                  // products && products.map((item, i) => <ProductItem key={i} item={item} />)
                  <>
                    {groupedChemicals?.map((element, i) => (
                      <div key={i}>
                        <h1 className="alphaNum" key={i + 1}>
                          {element[0].name.charAt(0)}
                        </h1>
                        <div className="p-box">
                          {element?.map((item, i) => (
                            <>
                              <Link
                                key={i}
                                to={`/product/${item.name
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}`}
                                className={
                                  i % 2 ? "productItem2" : "productItem2"
                                }
                              >
                                <img
                                  src={PlantGif}
                                  alt="product"
                                  className="productImg"
                                />
                                <div>{item.name}</div>
                              </Link>
                            </>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}

            {/* {count > resultPerPage &&
            <div className="paginationBox">
              <Pagination
                activePage={CurrentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={filterProductCount}
                onChange={setCurrentPageNo}

                className="paginationBox"

                // prevPageText="<"
                firstPageText="1st"
                lastPageText="Last"
                // nextPageText=">"
                itemClass='page-item'
                linkClass='page-link'
                activeClass='pageItemActive'
                activeLinkClass='pageLinkActive'
              />
            </div>
          } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
